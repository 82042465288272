@font-face {
    font-family: 'Nissan Brand';
    src: url('../assets/fonts/NissanBrand-Regular.eot');
    src: url('../assets/fonts/NissanBrand-Regular.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/NissanBrand-Regular.woff') format('woff'),
    url('../assets/fonts/NissanBrand-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Nissan Brand';
    src: url('../assets/fonts/NissanBrand-Bold.eot');
    src: url('../assets/fonts/NissanBrand-Bold.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/NissanBrand-Bold.woff') format('woff'),
    url('../assets/fonts/NissanBrand-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Nissan Brand';
    src: url('../assets/fonts/NissanBrand-Light.eot');
    src: url('../assets/fonts/NissanBrand-Light.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/NissanBrand-Light.woff') format('woff'),
    url('../assets/fonts/NissanBrand-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
